import { Box, Divider, Stack, Typography } from '@mui/material';

export type PricingPlanProps = {
  data: string[][];
  title: string;
  description: JSX.Element;
};

const PricingPlan = ({ data, title, description }: PricingPlanProps) => (
  <Stack
    direction={{ xs: 'column', md: 'row' }}
    spacing={{ xs: 4, md: 8, lg: 8 }}
    sx={{
      backgroundColor: 'primaryLight.500',
      p: { xs: '32px 16px', md: '32px 16px' },
      borderRadius: '20px',
      boxShadow: '1px 10px 30px 0px rgba(56, 69, 114, 0.10)',
    }}
  >
    <Stack
      sx={{ p: { xs: 0, md: '40px 24px', gr: '40px 48px' }, width: { xs: 'none', md: '50%' } }}
      spacing={2}
      color="secondaryGray.100"
    >
      <Typography sx={{ fontSize: { xs: '2rem', lg: '3rem' } }} variant="headline1">
        {title}
      </Typography>
      <Divider sx={{ width: '100%', backgroundColor: 'secondaryGray.100' }} />
      {/* <Typography variant="publicBody4Regular">{description}</Typography> */}
      {description}
    </Stack>
    <Box sx={{ width: { xs: 'none', md: '50%' }, p: { xs: 0, md: '40px 24px', gr: '40px 31px' } }}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" sx={{ color: '#fff', px: 1.5 }}>
          <Typography variant="publicBody5Light">Fixed Fee</Typography>
          <Typography variant="publicBody5Light">Spent</Typography>
          <Typography variant="publicBody5Light">Commission</Typography>
        </Stack>
        {data.map((item, ind) => (
          <Stack
            key={ind}
            direction="row"
            justifyContent="space-between"
            sx={{
              backgroundColor: 'primaryLight.700',
              color: '#fff',
              borderRadius: '33px',
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '66px',
                height: '66px',
                backgroundColor: '#513bd3',
                borderRadius: '33px',
              }}
            >
              <Typography variant="publicBody3Regular">{item[0]}</Typography>
            </Stack>
            <Stack justifyContent="center" alignItems="center">
              <Typography variant="publicBody3Regular">{item[1]}</Typography>
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '66px',
                height: '66px',
                backgroundColor: 'primaryLight.600',
                borderRadius: '33px',
              }}
            >
              <Typography variant="publicBody3Regular">{item[2]}</Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Box>
  </Stack>
);

export default PricingPlan;
