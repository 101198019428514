import { Typography } from '@mui/material';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';

export interface LocationI {
  id: number;
  state: string;
  address: JSX.Element;
  imgMobile: string;
  img: string;
  alt?: string;
}

const locations: LocationI[] = [
  {
    id: 1,
    state: 'Croatia',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        Ivana Lučića 2a
        <br />
        (Eurotower)
        <br />
        10000, Zagreb,
        <br />
        Croatia
      </Typography>
    ),
    imgMobile: getPublicImageUrl(EnumPublicPagesImage.ZagrebMobile),
    img: getPublicImageUrl(EnumPublicPagesImage.Zagreb),
    alt: 'Zagreb, ban Jelačić square',
  },
  {
    id: 2,
    state: 'Ireland',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        43 O'Connell Street Upper North City,
        <br />
        Dublin, D01 DK81 <br />
        <br />
        Ireland
      </Typography>
    ),
    imgMobile: getPublicImageUrl(EnumPublicPagesImage.DublinMobile),
    img: getPublicImageUrl(EnumPublicPagesImage.Dublin),
    alt: 'Galway, Galway cathedral',
  },
  {
    id: 4,
    state: 'USA',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        111 NE 1st Street, 8th <br /> Floor 88962, <br /> Miami, Florida 33132, <br /> United States
      </Typography>
    ),
    imgMobile: getPublicImageUrl(EnumPublicPagesImage.USAMobile),
    img: getPublicImageUrl(EnumPublicPagesImage.USA),
    alt: 'Miami beach',
  },
  {
    id: 5,
    state: 'Hungary',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        71173 Budapest, <br /> Pesti út 237.A/7, <br /> Hungary
      </Typography>
    ),
    imgMobile: getPublicImageUrl(EnumPublicPagesImage.HungaryMobile),
    img: getPublicImageUrl(EnumPublicPagesImage.Hungary),
    alt: 'Budapest, Hungary',
  },
];

export default locations;
