import { Box, Typography } from '@mui/material';
import LAYOUT_DEFAULTS from '../../../../../layout/utils';
import PricingPlan, { PricingPlanProps } from './components/PricingPlan';
import CustomAliceCarousel from '../../../../../components/custom-alice-carousel/CustomAliceCarousel';
import './media-pricing-carousel.scss';

const sponaMediaPricingData: (PricingPlanProps & { id: string })[] = [
  {
    id: '1',
    title: 'Pricing',
    description: (
      <Typography variant="publicBody4Regular">
        We provide ad accounts for a retainer fee of{' '}
        <Typography variant="publicBody4" component="span">
          $500
        </Typography>
        , with a small percentage of your monthly ad spending, which is flexible depending on the ad
        budget:
      </Typography>
    ),
    data: [
      ['$500', '$2k - $99k', '5%'],
      ['$500', '$100k - $499k', '4%'],
      ['$500', '$500k - $999k', '3%'],
      ['$500', '$1m+', '2%'],
    ],
  },
  {
    id: '2',
    title: 'Pricing for Greyhat Clients',
    description: (
      <Typography variant="publicBody4Regular">
        We provide ad accounts for a retainer fee of{' '}
        <Typography variant="publicBody4" component="span">
          $750
        </Typography>
        , along with a higher percentage of your monthly ad spending, reflecting the increased
        workload and associated risks:
      </Typography>
    ),
    data: [
      ['$750', '$2k - $99k', '7%'],
      ['$750', '$100k - $499k', '6%'],
      ['$750', '$500k - $999k', '5%'],
      ['$750', '$1m+', '4%'],
    ],
  },
];

const sponaMediaPricingCarouselElements = sponaMediaPricingData.map((item) => (
  <PricingPlan key={item.id} data={item.data} title={item.title} description={item.description} />
));

const PricingPlans = () => (
  <Box
    className="sponaMediaPricingCarousel"
    sx={{ mb: 12, position: 'relative', ...LAYOUT_DEFAULTS }}
  >
    <CustomAliceCarousel
      items={sponaMediaPricingCarouselElements}
      animationDuration={1100}
      autoPlayInterval={7000}
      buttonStyles={{
        display: { xs: 'none', lg: 'block' },
        top: '50%',
        right: '-44px',
      }}
    />
  </Box>
);

export default PricingPlans;
