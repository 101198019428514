import { Box, SxProps, Theme } from '@mui/material';
// import LightFilmLogo from '../../../assets/img/public-pages/lightfilm.png';
// import BellabeatLogo from '../../../assets/img/public-pages/bellabeat.png';
// import MuseumOfIllusionsLogo from '../../../assets/img/public-pages/moi.svg';
// import GameboostLogoBig from '../../../assets/img/public-pages/gameboost-big.svg';
// import BlvckLogo from '../../../assets/img/public-pages/blvck.png';

import MuseumOfIllusionsLogoSmall from '../../../assets/img/public-pages/moi.svg';
import GameboostLogoSmall from '../../../assets/img/public-pages/gameboost.svg';
import LightFilmLogoBig from '../../../assets/img/public-pages/lightfilm-big.png';
import MuseumOfIllusionsLogoBig from '../../../assets/img/public-pages/moi-big.svg';
import BlvckLogoBig from '../../../assets/img/public-pages/blvck-big.png';
import GameboostLogoBig from '../../../assets/img/public-pages/gameboost-big.svg';

import iva from '../../../assets/img/public-pages/team/iva.png';
import mihael from '../../../assets/img/public-pages/team/mihael.png';
import katarinac from '../../../assets/img/public-pages/team/katarina-c.png';
import katarinams from '../../../assets/img/public-pages/team/katarina-ms.png';
import kathryn from '../../../assets/img/public-pages/team/kathryn.png';
import stela from '../../../assets/img/public-pages/team/stela.png';
import mladena from '../../../assets/img/public-pages/team/mladena.png';
import nikaub from '../../../assets/img/public-pages/team/nika-ub.png';
import anamarija from '../../../assets/img/public-pages/team/anamarija.png';
import slaven from '../../../assets/img/public-pages/team/slaven.png';
import ana from '../../../assets/img/public-pages/team/ana.png';
import tomislav from '../../../assets/img/public-pages/team/tomislav.png';
import ivan from '../../../assets/img/public-pages/team/ivan.png';
import nikolina from '../../../assets/img/public-pages/team/nikolina.png';
import { getPublicImageUrl } from '../../../utils/helpers';
import { EnumPublicPagesImage } from '../../../utils/enums/public-page-images';

export interface TeamMember {
  id: number;
  title: string;
  name: string;
  img: string;
  borderRadius?: string;
  position?: Record<string, null | number>;
  color?: string;
  sx?: SxProps<Theme> | undefined;
}

// export const companyLogos = [
//   { src: BellabeatLogo, alt: 'bellabeat' },
//   { src: BlvckLogo, alt: 'blvck' },
//   { src: GameboostLogoBig, alt: 'gameboos' },
//   { src: LightFilmLogo, alt: 'lightfilm' },
//   { src: MuseumOfIllusionsLogo, alt: 'museum-of-illusions' },
// ];

export const companyLogosLessThanGreat: { src: string; alt: string; width?: string }[] = [
  { src: BlvckLogoBig, alt: 'logo_Blvck Paris', width: '55px' },
  { src: GameboostLogoSmall, alt: 'logo_Game boost', width: '55px' },
  { src: LightFilmLogoBig, alt: 'logo_light film intenrnational', width: '93px' },
  { src: MuseumOfIllusionsLogoSmall, alt: 'logo_museum of illusions', width: '55px' },
];

export const companyLogosGreaterThanGreat: { src: string; alt: string; width?: string }[] = [
  { src: BlvckLogoBig, alt: 'logo_Blvck Paris', width: '80px' },
  { src: GameboostLogoBig, alt: 'logo_Game boost', width: '80px' },
  { src: LightFilmLogoBig, alt: 'logo_light film intenrnational', width: '105px' },
  { src: MuseumOfIllusionsLogoBig, alt: 'logo_museum of illusions', width: '80px' },
];

export const ourJourneyArray = [
  {
    subtitle: 'May 2018',
    content:
      "Spona's journey starts at the Spark.me Startup Competition in Montenegro - the eminent competition for disruptive ideas in the field of tech and business in Southeast Europe. Spona, then known as Top Digital Agency, was awarded first place, impressing the judges with its innovative vision.",
  },
  {
    subtitle: 'February 2019',
    content:
      'The next big step for the team was running a record breaking crowd-funding campaign on Funderbeam. Within only 26 hours of releasing the campaign for the public, Top Digital Agency funded 141% of its initially set goal, making it the quickest funded Funderbeam campaign as of 2019.',
  },
  {
    subtitle: 'August 2019',
    content:
      'In August of 2019, Top Digital Agency was proud to become the first investment for the venture capital fund Fil Rouge capital - one of the biggest startup funds in Croatia and the broader region, according to the 2021 European Capital Report.',
  },
  {
    subtitle: 'July 2021',
    content:
      "In July 2021, we caught the attention of Feelsgood Capital, Croatia's first social impact fund. By receiving the fund's first ever investment, we were able to further build key elements of our B2B platform – a crucial step in our development as a company.",
  },
  {
    subtitle: 'October 2022',
    content:
      'Two years later, Top Digital Agency has solidified its customer base and facilitated numerous successful projects. Around this time, the concept for the comprehensive hiring, management and finance platform Spona was pioneered. The team started working on new functionalities and the reimagined visual identity and brand that reflects the values of Spona today.',
  },
  {
    subtitle: 'June 2023',
    content:
      'The first version of End-to-End Project Management, TDA’s all-in-one project management tool, is released and ready to be used by buyers and digital service sellers. It marks TDA’s biggest product launch since 2018.',
  },
  {
    subtitle: 'September 2023',
    content:
      'The rebrand is officially launched: Top Digital Agency becomes the Spona platform we know and love today. Ever since then, the team has been working on polishing the platform and introducing exciting new features to help streamline all phases of the project lifecycle. ',
  },
  {
    subtitle: 'October 2023',
    content:
      'Perhaps the most fulfilling milestone on our journey has been expanding our network of digital service sellers to over 10,000 agencies – a testament to how serving the needs of both sellers and buyers is at the heart of everything we do.',
  },
];

export const ourJourneyCarouselElementsMobile = [
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile)}
    component="img"
    width="100%"
    alt="Sales team member presenting results to other team members."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile2)}
    component="img"
    width="100%"
    alt="Two colleagues casually chatting in the hallway."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile3)}
    component="img"
    width="100%"
    alt="Three colleagues in a group photo."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile4)}
    component="img"
    width="100%"
    alt="Close-up of a person typing on the computer."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile5)}
    component="img"
    width="100%"
    alt="Two colleagues looking at the phone."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile6)}
    component="img"
    width="100%"
    alt="Two colleagues walking down the hallway."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile7)}
    component="img"
    width="100%"
    alt="A person typing on the computer and smiling."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarouselMobile8)}
    component="img"
    width="100%"
    alt="Two colleagues celebrating success with a high-five."
  />,
];

export const ourJourneyCarouselElements = [
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel)}
    component="img"
    width="100%"
    alt="Sales team member presenting results to other team members."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel2)}
    component="img"
    width="100%"
    alt="Two colleagues casually chatting in the hallway."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel3)}
    component="img"
    width="100%"
    alt="Three colleagues in a group photo."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel4)}
    component="img"
    width="100%"
    alt="Close-up of a person typing on the computer."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel5)}
    component="img"
    width="100%"
    alt="Two colleagues looking at the phone."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel6)}
    component="img"
    width="100%"
    alt="Two colleagues walking down the hallway."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel7)}
    component="img"
    width="100%"
    alt="A person typing on the computer and smiling."
  />,
  <Box
    src={getPublicImageUrl(EnumPublicPagesImage.OurJourneyCarousel8)}
    component="img"
    width="100%"
    alt="Two colleagues celebrating success with a high-five."
  />,
];

export const teamMembers: any[] = [
  {
    id: 1,
    title: 'Business Analyst',
    name: 'Mihael Bencic',
    img: mihael,
    alt: 'Mihael Bencic, Business Analyst at Spona',
  },
  {
    id: 2,
    title: 'Office Manager',
    name: 'Katarina Cavrag',
    img: katarinac,
    alt: 'Katarina Cavrag, Office Manager at Spona',
  },
  {
    id: 3,
    title: 'Product Manager',
    name: 'Kathryn Foulkes',
    img: kathryn,
    alt: 'Kathryn Foulkes, Product Manager at Spona',
  },
  {
    id: 4,
    title: 'Marketing & Sales Support Intern',
    name: 'Stela Brezovic',
    img: stela,
    alt: 'Stela Brezović, Marketing and Sales intern at Spona',
  },
  {
    id: 7,
    title: 'UI/UX Designer',
    name: 'Ana-Marija Gusa',
    img: anamarija,
    alt: 'Ana Marija Gusa, UX/UI Designer at Spona',
  },
  {
    id: 8,
    title: 'Junior Consultant',
    name: 'Mladena Loncar',
    img: mladena,
    alt: 'Mladena Loncar, Junior Consultant at Spona',
  },
  {
    id: 9,
    title: 'Global Sales Account & Partnerships Manager',
    name: 'Nika Ujevic-Beusan',
    img: nikaub,
    alt: 'Nika Ujevic-Beusan, Global Sales Account and Partnerships Manager at Spona',
  },
  {
    id: 11,
    title: 'Head of Legal',
    name: 'Iva Matijevic',
    img: iva,
    alt: 'Iva Matijevic, Head of Legal at Spona',
  },
  {
    id: 12,
    title: 'Finance',
    name: 'Slaven Vukasovic',
    img: slaven,
    alt: 'Slaven Vukasovic, CFO at Spona',
  },
  {
    id: 13,
    title: 'Sales Account & Partnership Manager',
    name: 'Katarina Mijic Sipos',
    img: katarinams,
    alt: 'Katarina Mijic Sipos, Sales Account & Partnership Manager at Spona',
  },
  {
    id: 14,
    title: 'Copywriter',
    name: 'Ana Cutura',
    img: ana,
    alt: 'Ana Cutura, Copywriter at Spona',
  },
  {
    id: 16,
    title: 'Development Team Lead',
    name: 'Tomislav Smetko',
    img: tomislav,
    alt: 'Tomislav Smetko, Development Team Lead at Spona',
  },
  {
    id: 17,
    title: 'Digital Marketing Specialist',
    name: 'Ivan Paljetak',
    img: ivan,
    alt: 'Ivan Paljetak, Digital Marketing Specialist at Spona',
  },
  {
    id: 19,
    title: 'Business Analyst',
    name: 'Nikolina Misic',
    img: nikolina,
    alt: 'Nikolina Misic, Business Analyst at Spona',
  },
];
